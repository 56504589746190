<template>
	<div class="link">
		<Nav></Nav>
		<TopCover :pageMsg="pageMsg"></TopCover>
		<LinkC></LinkC>
	</div>
</template>

<script>
import TopCover from '@/components/TopCover.vue'
import LinkC from '@/components/Link.vue'
import Nav from '@/components/Nav.vue'

export default {
    metaInfo: {
	    title: '链接 - KASUIEの次元',
	    meta: [
		    {
			    name: 'description',
	            content: '友情链接，关于我',
		    },
	        {
	            name: 'keywords',
	            content: '友情链接，关于我，QQ群，github，telegram，推荐链接，二次元老婆'
	        }
	  ]
    },
	name: 'Link',
	data() {
		return {
			pageMsg:{
				title: '链接',
				imgUrl: 'https://i.loli.net/2021/07/15/ZMjSOHPWYgVfnQs.jpg',
				article: ''
			},
		}
	},
	components: {
		TopCover,
		LinkC,
		Nav
	},
	methods: {
		
	},
	created() {
		document.title = 'KASUIEの链接'
		let title
		setTimeout(function() {
		   	title = document.title
		}, 1000);
		window.onfocus = function () {
			document.title = '恢复正常了...';
		    setTimeout(function() {
				document.title = title
			}, 1000);
		};
		window.onblur = function () {
			setTimeout(function() {
				document.title = '快回来~页面崩溃了'
			}, 5000);
		};
	},
	mounted() {
		document.body.style.overflow='hidden'
		setTimeout(() => {
			this.$store.commit('showLoading')
			document.body.style.overflow=''
		},2000)
	},
	destroyed() {
		this.$store.commit('loading')
	}
}
</script>

<style>
.link{
	position: relative;
}
</style>
