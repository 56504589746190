<template>
	<div class="linkc">
		<el-collapse v-model="activeNames">
		  <el-collapse-item title="我的" name="1">
			<template slot="title"><i class="header-icon el-icon-magic-stick"></i><span class="link-title">我的</span></template>
		    <div>
		    	<ul class="mys items">
		    		<li v-for="(my,index) in mys" :key="index">
		    			<div class="link-bg">
		    				<div class="bg" :style="{backgroundImage: 'url(/img/'+my.pictureAddress+'1.png)'}"></div>
		    				<div class="link-avatar"><img v-lazy="'/img/'+my.pictureAddress+'1.png'" alt="pictureAddress"></div>
		    			</div>
		    			<div class="link-btn">
		    				<el-button type="primary" round><a :href="my.webAddress" target="_blank">找我玩</a></el-button>
		    			</div>
		    			<div class="link-info">
		    				<h3 v-text="my.webName"></h3>
		    				<div v-text="my.detail" class="detail"></div>
		    			</div>
		    		</li>
					<el-empty :image-size="200" v-if='mys.length==0'></el-empty>
		    		<li v-if="admin">
		    			<el-input v-model="web.webAddress" placeholder="webAddress"></el-input>
		    			<el-input v-model="web.webName" placeholder="webName"></el-input>
		    			<el-input v-model="web.pictureAddress" placeholder="pictureAddress"></el-input>
		    			<el-input v-model="web.detail" placeholder="detail"></el-input>
		    			<el-button type="primary" round @click='add(1)'>添加</el-button>
		    		</li>
		    	</ul>
		    </div>
		  </el-collapse-item>
		  <el-collapse-item title="小伙伴们" name="2">
			<template slot="title"><i class="header-icon el-icon-ship"></i><span class="link-title">小伙伴们</span></template>
		    <div>
				<ul class="friends items">
					<li v-for="(friend,index) in friends" :key="index">
						<div class="link-bg">
							<div class="bg" :style="{backgroundImage: 'url('+friend.pictureAddress+')'}"></div>
							<div class="link-avatar"><img v-lazy="friend.pictureAddress" alt="pictureAddress"></div>
						</div>
						<div class="link-btn">
							<el-button type="primary" round><a :href="friend.blogAddress" target="_blank">去找TA玩</a></el-button>
						</div>
						<div class="link-info">
							<h3 v-text="friend.blogName"></h3>
							<div v-text="friend.detail" class="detail"></div>
						</div>
					</li>
					<el-empty :image-size="200" v-if='friends.length==0'></el-empty>
					<li v-if="admin">
						<el-input v-model="web.blogAddress" placeholder="blogAddress"></el-input>
						<el-input v-model="web.blogName" placeholder="blogName"></el-input>
						<el-input v-model="web.pictureAddress" placeholder="pictureAddress"></el-input>
						<el-input v-model="web.detail" placeholder="detail"></el-input>
						<el-button type="primary" round @click='add(2)'>添加</el-button>
					</li>
				</ul>
			</div>
		  </el-collapse-item>
		  <el-collapse-item title="推荐链接" name="3">
		  	<template slot="title"><i class="header-icon el-icon-cold-drink"></i><span class="link-title">推荐链接</span></template>
		    <div>
		  		<ul class="tools items">
		  			<li v-for="(tool,index) in tools" :key="index">
		  				<div class="link-bg">
		  					<div class="bg" :style="{backgroundImage: 'url('+tool.pictureAddress+')'}"></div>
		  					<div class="link-avatar"><img v-lazy="tool.pictureAddress" alt="pictureAddress"></div>
		  				</div>
		  				<div class="link-btn">
		  					<el-button type="primary" round><a :href="tool.toolAddress" target="_blank">去康康</a></el-button>
		  				</div>
		  				<div class="link-info">
		  					<h3 v-text="tool.toolName"></h3>
		  					<div v-text="tool.detail" class="detail"></div>
		  				</div>
		  			</li>
					<el-empty :image-size="200" v-if='tools.length==0'></el-empty>
					<li v-if="admin">
						<el-input v-model="web.toolAddress" placeholder="toolAddress"></el-input>
						<el-input v-model="web.toolName" placeholder="toolName"></el-input>
						<el-input v-model="web.pictureAddress" placeholder="pictureAddress"></el-input>
						<el-input v-model="web.detail" placeholder="detail"></el-input>
						<el-input v-model="web.type" placeholder="type"></el-input>
						<el-button type="primary" round @click='add(3)'>添加</el-button>
					</li>
		  		</ul>
		  	</div>
		  </el-collapse-item>
		  <el-collapse-item title="小可爱们" name="4">
		  	<template slot="title"><i class="header-icon el-icon-lollipop"></i><span class="link-title">小可爱们</span></template>
		    <div>
		  		<ul class="girls items">
		  			<li v-for="(girl,index) in girls" :key="index">
		  				<div class="link-bg">
		  					<div class="bg" :style="{backgroundImage: 'url('+girl.girlAddress+')'}"></div>
		  					<div class="link-avatar"><img v-lazy="girl.girlAddress" alt="girlAddress"></div>
		  				</div>
		  				<div class="link-btn">
		  					<el-button type="primary" round><a :href="girl.girlLink" target="_blank">戳我了解</a></el-button>
		  				</div>
		  				<div class="link-info">
		  					<h3 v-text="girl.girlName"></h3>
		  					<div v-text="girl.detail" class="detail"></div>
		  				</div>
		  			</li>
					<el-empty :image-size="200" v-if='girls.length==0'></el-empty>
					<li v-if="admin">
						<el-input v-model="web.girlLink" placeholder="girlLink"></el-input>
						<el-input v-model="web.girlName" placeholder="girlName"></el-input>
						<el-input v-model="web.girlAddress" placeholder="girlAddress"></el-input>
						<el-input v-model="web.detail" placeholder="detail"></el-input>
						<el-button type="primary" round @click='add(4)'>添加</el-button>
					</li>
		  		</ul>
		  	</div>
		  </el-collapse-item>
		</el-collapse>
	</div>
</template>

<script>
export default {
	name: 'LinkC',
    data() {
      return {
		  activeNames: ['1','2','3','4'],
		  mys: [],
		  friends: [],
		  tools: [],
		  girls: [],
		  myImg: ['github','qq','bilibili','telegram','email'],
		  admin: false,
		  web: {
			  id: '',
			  webName: '',
			  blogName: '',
			  toolName: '',
			  girlName: '',
			  webAddress: '',
			  blogAddress: '',
			  toolAddress: '',
			  girlLink: '',
			  pictureAddress: '',
			  datail: '',
			  girlAddress:'',
			  type: null
		  }
      };
    },
    methods: {
		getAllLinks(){
			let that = this
			this.axios.post('links',{}).then(function(results){
				that.friends = results.data.friends[0]
				that.girls = results.data.girls[0]
				that.mys = results.data.mys[0]
				that.tools = results.data.tools[0]
			},function(err){
				console.log(err)
			})
		},
		add(num){
			let that = this
			console.log(that.web);
			if (num==1) {
				this.axios.post('saveMy',that.web).then(function(results){
					console.log(results.data);
				},function(err){
					console.log(err)
				})
			} else if(num==2){
				this.axios.post('saveBlog',
					that.web
				).then(function(results){
					console.log(results.data);
				},function(err){
					console.log(err)
				})
			}else if(num==3){
				this.axios.post('saveTool',
					that.web
				).then(function(results){
					console.log(results.data);
				},function(err){
					console.log(err)
				})
			}else if(num==4){
				this.axios.post('saveGirl',
					that.web
				).then(function(results){
					console.log(results.data);
				},function(err){
					console.log(err)
				})
			}
		}
    },
	created() {
		this.getAllLinks()
		if (this.$store.state.login&&this.$store.state.user.level>2) {
			this.admin = true
		}
	}
  }
</script>

<style>
.linkc{
	width: 60%;
	margin: 0 auto;
}
.el-collapse-item__wrap,.el-collapse-item__header{
	font-size: 2em;
	background-color: unset;
}
.el-collapse-item__arrow{
	color: white;
}
.header-icon,.link-title{
	color: #2d8cef;
}
.el-collapse>div:nth-child(even) .header-icon,.el-collapse>div:nth-child(even) .link-title{
	color: #f17d92;
}
/* .link-title::before{
	content: '';
	position: absolute;
	left: -1em;
	top: .3em;
	width: .6em;
	height: .6em;
	border: 3px solid #29BDFF;
	border-radius: 50%;
} */
.items{
	display: flex;
	flex: auto;
	flex-wrap: wrap;
	justify-content: center;
}
.el-empty{
	width: 100%;
}
.items li{
	width: 290px;
	height: 240px;
	list-style: none;
	margin: 3em;
	border-radius: .5em;
	background-color: var(--theme-background-color);
}
.items li:hover .bg{
	filter: blur(0);
}
.items li:hover .link-avatar img{
	transform: rotate3d(1, 1, 0, 360deg)
}
.link-bg{
	position: relative;
	height: 90px;
}
.link-bg .bg{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: .5em;
	filter: blur(1px) brightness(.8);
	background-size: cover;
	transition: all .3s ease-in-out;
}
.link-avatar{
	position: absolute;
	bottom: -50px;
	left: .5em;
	border: 4px solid #FFF;
	border-radius: 100%;
	background-color: #fff;
	box-shadow: 0 0 5px rgb(0 0 0 / 50%);
}
.link-avatar img{
	width: 80px;
	height: 80px;
	border-radius: 100%;
	object-fit: cover;
	overflow: hidden;
	vertical-align: middle;
	transition: all .3s ease-in-out;
}
.link-btn{
	padding: .9em 1em;
	text-align: right;
}
.el-button a{
	color: white;
	text-decoration: none;
}
.link-info h3{
	color: var(--theme-color);
}
.link-info .detail{
	overflow: hidden;
	color: var(--theme-color);
	text-overflow: ellipsis;
	white-space: nowrap;
	min-height: 20px;
}
.darkApp .item li{
	background-color: rgb(49 40 40 / 50%);
}
@media screen and (max-width: 770px) {
	.linkc{
		width: 95%;
	}
	.items li{
		margin: 3em 0;
	}
}
</style>
